import * as React from 'react';
import * as URL from "../../services/url";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Faciltyassign(props) {
    const [facilities, setFacilities] = useState([])
    const [catchment, setCatchment] = useState([])
    const [open, setOpen] = React.useState(false);
    const [nonLabsList, setNonLabsList] = useState([]);
    const [LabsList, setLabsList] = useState([]);
    const [selectedNonLab, setSelectedNonLab] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [LabsLatLong, setLabsLatLong] = React.useState([]);
    const { id } = props

    let daysObj = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

    useEffect(() => {
        open && getstrddata()
    }, [open])

    async function getstrddata() {
        let reqBody = {
            "strdid": id,
        }
        try {
            const response = await axios.post(URL.getassignedlabs, reqBody);
            if (response && response.status == 200) {
                setSelectedFacilities(response.data.result.facdata)
                setLabsList(response.data.result.facids)
                if (response.data.result.facdata.length > 0) {
                    let latlong = response.data.result.facdata?.map((l) => {
                        if (l.latitude != null && l.longitude != null) {
                            return [l.latitude, l.longitude]
                        }
                    })
                    setLabsLatLong(latlong)
                    getnlabs()
                }
                console.log(response);
            }

            const response1 = await axios.post(URL.getassignednlabs, reqBody);
            if (response1 && response1.status == 200) {
                setSelectedNonLab(response1.data.result.facdata)
                setNonLabsList(response1.data.result.facids)
                console.log(response1);
            }
        } catch (error) {
            toast.error(error)
            console.error('Error:', error);
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const searchFacility = async (e) => {
        if (e && e.target) {
            let reqBody = {
                "searchtxt": e.target.value,
            }
            try {
                const response = await axios.post(URL.getlabs, reqBody);
                if (response && response.status == 200) {

                    setFacilities(response.data.locations)
                }
            } catch (error) {
                toast.error(error)
                console.error('Error:', error);
            }
        }
    }

    const selectFacility = async (e, v, r) => {
        setSelectedFacilities(v)
        // console.log(e,v,r);
        // let latlong = v.map((l)=>{
        //     if(l.latitude!=null && l.longitude!=null){
        //         return [l.latitude ,l.longitude]
        //     }
        // })
        // setLabsLatLong(latlong)
        // getnlabs()
        // console.log(latlong);

        if (r == "selectOption") {
            let newlabs = [...LabsList, { facility: '', assigned_days: [] }]
            newlabs[LabsList.length].facility = v[v.length - 1].dno_id
            setLabsList(newlabs)


        } else if (r == "removeOption" || r == 'clear') {
            let newid = v.map((m) => {
                return m.dno_id
            })
            let newlabs = [...LabsList].filter((l) => {
                return newid.includes(l.facility)
            })
            setLabsList(newlabs)
            console.log(newlabs, newid);
        }
    }

    const handleFacChange = (event, index, id) => {
        console.log(event.target, index);
        let newlabs = [...LabsList]
        newlabs[index].assigned_days = event.target.value
        setLabsList(newlabs)
        console.log(newlabs);
    };

    const selectNonLab = async (e, v, r) => {
        setSelectedNonLab(v)


        if (r == "selectOption") {
            let newlabs = [...nonLabsList, { facility: '', assigned_days: [] }]
            newlabs[nonLabsList.length].facility = v[v.length - 1].cathment_key
            setNonLabsList(newlabs)
            console.log(newlabs,);
        } else if (r == "removeOption" || r == 'clear') {
            let newid = v.map((m) => {
                return m.cathment_key
            })
            let newlabs = [...nonLabsList].filter((l) => {
                return newid.includes(parseInt(l.facility))
            })
            setNonLabsList(newlabs)
            console.log(newlabs, newid, v);
        }
    }

    const handleNonFacChange = (event, index, id) => {
        console.log(event.target.value);
        let newlabs = [...nonLabsList]
        newlabs[index].assigned_days = event.target.value
        setNonLabsList(newlabs)
        console.log(newlabs);
    };

    const clearSelect = async () => {
        setFacilities([])
    }

    const clearNonFacSelect = async () => {
        setCatchment([])
    }

    async function getnlabs() {
        if (LabsLatLong.length > 0) {

            let reqBody = {
                "latlongarr": LabsLatLong,
            }
            try {
                const response = await axios.post(URL.getnlabs, reqBody);
                if (response && response.status == 200) {
                    setCatchment(response.data.locations)
                }
            } catch (error) {
                toast.error(error)
                console.error('Error:', error);
            }
        }
    }

    const submitData = async () => {
        console.log(nonLabsList, LabsList);
        let request = {
            "strdid": id,
        }
        try {
            const response = await axios.post(URL.assignstrdlabs, { ...request, "facilityassigned": LabsList });
            if (response && response.status == 200) {

                console.log(response);
            }

            // const response1 = await axios.post(URL.assignstrdnlabs, {...request,"facilityassigned":nonLabsList});
            // if (response1 && response1.status == 200) {

            //     console.log(response1);
            // }
            handleClose()
        } catch (error) {
            toast.error(error)
            console.error('Error:', error);
        }
    }

    return (
        <React.Fragment>
            <Button onClick={() => handleOpen()} class="assignbtn">Assign</Button>
            <Modal
                className='FacModal'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: "50vw", height: "80vh", overflowX: 'auto' }}>
                    <div className='textAlignEnd'>
                        <CloseOutlinedIcon onClick={() => handleClose()} className='mapSectCloseIcon'></CloseOutlinedIcon>
                    </div>
                    <Grid container spacing={2} className='mt-10px main-containerGrid'>

                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={facilities}
                                multiple
                                filterSelectedOptions
                                value={selectedFacilities}
                                getOptionLabel={(option) => option.facility_name}
                                sx={{ width: 300 }}
                                onInputChange={(e) => searchFacility(e)}
                                onChange={(e, v, r) => selectFacility(e, v, r)}
                                renderInput={(params) => <TextField {...params} label="Search Referral Facility Name" />}
                            />
                            <Grid container spacing={2} className='mt-10px'>
                                {selectedFacilities.map((fac, index) => (<React.Fragment key={index}>

                                    <Grid item xs={6}>
                                        <TextField id="standard-basic" variant="standard" disabled value={fac.facility_name} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ m: 1, width: 270 }}>
                                            <InputLabel id="demo-multiple-name-label">Operational Days</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={LabsList[index].assigned_days}
                                                onChange={(e) => handleFacChange(e, index, fac.dno_id)}
                                                input={<OutlinedInput label="Operational Days" />}
                                                label="Operational Days"
                                                className='daysSelect'
                                                MenuProps={MenuProps}
                                            >
                                                {daysObj.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>))}
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={catchment}
                                multiple
                                getOptionLabel={(option) => option.facility_name}
                                value={selectedNonLab}
                                onChange={(e,v,r)=>selectNonLab(e,v,r)}
                                renderInput={(params) => <TextField {...params} label="Select catchments" />}
                            />
                            <Grid container spacing={2} className='mt-10px'>
                                {selectedNonLab.map((nonFac,index)=>
                                <React.Fragment>
                                <Grid item xs={6}>
                                    <TextField id="standard-basic" value={nonFac.facility_name} variant="standard" disabled />

                                </Grid>
                                <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: 270 }}>
                                    <InputLabel id="demo-multiple-name-label">Operational Days</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='daysSelect'
                                        multiple
                                        value={nonLabsList[index].assigned_days}
                                        onChange={(e)=>handleNonFacChange(e,index,nonFac.cathment_key)}
                                        input={<OutlinedInput label="Operational Days" />}
                                        label="Operational Days"
                                        MenuProps={MenuProps}
                                    >
                                        {daysObj.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>
                                </React.Fragment>
                                )}
                            </Grid>
                        </Grid> */}



                    </Grid>

                    <Grid item xs={12} className='button-facility'>
                        <div>
                            <Button onClick={submitData} class='assignbtn'>Submit</Button>
                        </div>
                    </Grid>


                </Box>
            </Modal>
        </React.Fragment>
    )
}