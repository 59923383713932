import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapContainer = (props) => {
    const { userLatLong } = props;
    const mapStyles = {
        width: '100%',
        height: '400px',
    };

    return (
        <Map
            google={props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={{
                lat: userLatLong[0],
                lng: userLatLong[1],
            }}
        >
            <Marker position={{ lat: userLatLong[0], lng: userLatLong[1] }} />
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB0vsa7Ll6IH1zPClWcZAOaSt4DydOTLV4',
})(MapContainer);
