export const apiUrl = "https://f65e-103-252-7-235.ngrok.io";
export const baseUrl = "https://f65e-103-252-7-235.ngrok.io";

// export const getStriderOtp =  `${baseUrl}/getotp`
export const getStriderOtp =  "https://mdnoservice.duredemos.com/getotp"
export const verifyStriderOtp =  "https://mdnoservice.duredemos.com/verifyotp"
export const getusers =  "https://mdnoservice.duredemos.com/strd/getusers"
export const updatestatus =  "https://mdnoservice.duredemos.com/strd/updatestatus"
export const downloaddoc = "https://mdnoservice.duredemos.com/strd/getuserdoc"
export const getlabs = "https://mdnoservice.duredemos.com/lab/getlabs"
export const assignfacility = "https://mdnoservice.duredemos.com/strd/assignfacility"
export const getnlabs = "https://mdnoservice.duredemos.com/nlab/getnlabs"
export const getassignednlabs = "https://mdnoservice.duredemos.com/nlab/getassignednlabs"
export const assignstrdnlabs = "https://mdnoservice.duredemos.com/nlab/assignstrdnlabs"
export const getassignedlabs = "https://mdnoservice.duredemos.com/lab/getassignedlabs"
export const assignstrdlabs = "https://mdnoservice.duredemos.com/lab/assignstrdlabs"
export const uploadExcel = "https://mdnoservice.duredemos.com/lab/uploadexcel"
export const getStgLabs = "https://mdnoservice.duredemos.com/lab/getstglabs"
export const getAllNonLabs = "https://mdnoservice.duredemos.com/nlab/getallnonlabs"
export const getAllLabs = "https://mdnoservice.duredemos.com/lab/getalllabs"
export const getLabsNlab = "https://mdnoservice.duredemos.com/lab/getassignednlabs"
export const assignLabsNlab = "https://mdnoservice.duredemos.com/lab/assignnonlabs"
export const labUpdateStatus = "https://mdnoservice.duredemos.com/lab/updatestatus"
export const nonLabUpdateStatus = "https://mdnoservice.duredemos.com/nlab/updatestatus"
export const LabsOnboardingForm =  "https://evecidkfaocudnp.form.io/mdnolabs"
export const labsRegistartion = "https://mdnoservice.duredemos.com/lab/register"
export const addAdmin = "https://mdnoservice.duredemos.com/admin/addadmin"
export const getAllAdmins = "https://mdnoservice.duredemos.com/admin/getadmins"
