import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

function PreLoginRoutes({ children }) {
    const location = useLocation();
    const userRole = localStorage.getItem("userRole");
    const authData = JSON.parse(localStorage.getItem("auth"));
    const isAuth = authData ? authData.isAuth : false;

    if (!isAuth) {
        return children
    }
    else {
        return <Navigate to="/strider-dashboard" />;
    }
}

export default PreLoginRoutes