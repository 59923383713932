import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function ConfirmationPopup(props) {
  const {
    openPopup,
    handleOpenConfirmationPopup,
    handleCloseConfirmationPopup,
    handleAction,
    selectedStatus,
    selectedPhoneNumber,
  } = props;

  let status =
    selectedStatus == "approved"
      ? "approve"
      : selectedStatus == "rejected"
      ? "reject"
      : "suspend";
  return (
    <div>
      {/* <Button onClick={handleOpenConfirmationPopup}>Open modal</Button> */}
      <Modal
        open={openPopup}
        onClose={handleCloseConfirmationPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ConfirmationPopupWrapper"
      >
        <Box sx={style} className="modal-labpopup">
          <div className="alertModalHeader">
            <CloseIcon onClick={handleCloseConfirmationPopup}></CloseIcon>
          </div>
          <Typography
            id="modal-modal-title"
            className="alertBoxText"
            variant="h6"
            component="h2"
          >
            Do you really want to {status} the user ?
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Do you really want to {status} the user ?
                    </Typography> */}
          <div className="modalButtonsWrapper">
            <button
              className="modal-buttton"
              onClick={() => handleAction(selectedPhoneNumber, selectedStatus)}
            >
              Yes
            </button>
            <button
              onClick={handleCloseConfirmationPopup}
              className="modal-buttton modal-secondaryButtton"
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
