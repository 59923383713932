import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

function AdminAccessRoute({ children }) {
    const location = useLocation();
    const userRole = localStorage.getItem("userRole");
    const authData = JSON.parse(localStorage.getItem("auth"));
    const isAuth = authData ? authData.isAuth : false;

    if (isAuth && userRole == "super_admin") {
        return children
    }
    else {
        return <Navigate to="/" />;
    }
}

export default AdminAccessRoute