import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import * as URL from "../../services/url";
import axios from "axios";
import { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import Loader from "../../components/Loader/Loader";
import Catchmentassign from "./Catchmentassign";
import { toast } from "react-toastify";
import Sidebar from "../../components/Sidebar/sidebar";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TableContainer, Paper, TextField } from "@material-ui/core";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import Modal from "@mui/material/Modal";
import MapContainer from "./MapComponent";
import PlaceIcon from '@mui/icons-material/Place';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ToastHandler, getErrorString } from '../../constants/utils';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';
import ConfirmationPopup from '../../components/Modals/ConfirmationPopup';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LabsDashboard() {
  const [loading, setLoading] = useState(false);
  const [LabList, setLabList] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [userLatLong, setUserLatLong] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [disabledSyncIcon, setDisabledSyncIcon] = useState(false);
  const { showErrorToast, showSuccessToast } = ToastHandler();

  let LabsColumns = [];
  let LabAddedKeys = new Set();

  const handleOpenMap = (rowData) => {
    setOpenMap(true);
    setUserLatLong([rowData?.latitude, rowData?.longitude]);
  };
  const handleCloseMap = () => {
    setOpenMap(false);
    setUserLatLong("");
  };

  useEffect(() => {
    getAllData();
  }, [pageSize, pageNumber]);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: sizePerPage,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "400",
        value: 400,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "All",
        value: LabList.length,
      },
    ],
    onPageChange: function (page, sizePerPage) {
      setPageNumber(page - 1);
      setPageSize(pageSize);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      // console.log(">>>page>>sizePerPage", page, sizePerPage);
    },
  });

  const handleAction = async (labId, status) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", labId);
    formData.append("status", status);
    try {
      const response = await axios.post(URL.labUpdateStatus, formData);
      setLoading(false);
      if (response.status == 200) {
        console.log(response, "::>>response");
        showSuccessToast(response.data.message);
        getAllData();
        setOpenPopup(false)
      }
    } catch (error) {
      setLoading(false);
      showErrorToast(getErrorString(error));
      console.error("Error:", error);
    }
  };

  const getAllData = async () => {
    setLoading(true);
    let reqBody = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    try {
      const response = await axios.post(URL.getAllLabs, reqBody);
      setLoading(false);
      if (response.status == 200) {
        let responseData = response.data.Data;
        if (LabList.length === 0 || pageNumber === 0) {
          setLabList(responseData);
        } else {
          const mergedArray = LabList.concat(
            responseData.filter(
              (item2) => !LabList.some((item1) => item1.dno_id === item2.dno_id)
            )
          );
          setLabList(mergedArray);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleLoadMoreData = async () => {
    setLoading(true);
    let reqBody = {
      pageNumber: 0,
      pageSize: 5000,
    };
    try {
      const response = await axios.post(URL.getAllLabs, reqBody);
      setLoading(false);
      if (response.status == 200) {
        let responseData = response.data.Data;
        if (LabList.length === 0 || pageNumber === 0) {
          setLabList(responseData);
        } else {
          const mergedArray = LabList.concat(
            responseData.filter(
              (item2) => !LabList.some((item1) => item1.dno_id === item2.dno_id)
            )
          );
          setLabList(mergedArray);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  }

  const handleOpenConfirmationPopup = (phone, selectedStatus) => {
    setOpenPopup(true)
    setSelectedPhoneNumber(phone);
    setSelectedStatus(selectedStatus);
  };

  const handleCloseConfirmationPopup = () => {
    setOpenPopup(false)
    setSelectedPhoneNumber("");
    setSelectedStatus("");
  };

  let transformedData = LabList.length > 0 && LabList.forEach(item => {
    const entries = Object.entries(item);
    entries.forEach(([key, value]) => {
      if (!LabAddedKeys.has(key)) {
        let column = { dataField: key, text: key, sort: true };
        if (key === 'dno_id') {
          column.text = 'Facility Id';
        }
        if (key === 'facility_name') {
          column.text = 'Referral Facility';
          column.formatter = (cell, row) => {
            console.log(cell, row, "::>>ROWW")
            return (
              <p>{row?.facility_name ? row?.facility_name : "NA"}</p>
            );
          };
        }
        if (key === 'contact_number') {
          column.text = 'Contact Number';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.contact_number ? row?.contact_number : "NA"}</p>
            );
          };
        }
        if (key === 'email_address') {
          column.text = 'Email Address';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.email_address ? row?.email_address : "NA"}</p>
            );
          };
        }
        if (key === 'latitude') {
          column.text = 'View Location';
          column.formatter = (cell, row) => {
            return (
              <>
                {row.latitude == null ||
                  row.latitude == "" ||
                  row.longitude == null ||
                  row.longitude == "" ? (
                  "No location found"
                ) : (
                  <PlaceIcon
                    className="locationIcon"
                    onClick={() => handleOpenMap(row)}
                  ></PlaceIcon>
                )}
              </>
            );
          };
        }
        if (key === "catchmentassign") {
          column.text = "Referring Facility";
          column.formatter = (cell, row) => {
            return (
              <>
                <Catchmentassign
                  id={row?.dno_id}
                  lat={row?.latitude}
                  long={row?.longitude}
                />
              </>
            );
          };
        }
        if (key === 'approval') {
          column.text = 'Status';
          column.formatter = (cell, row) => {
            console.log(row, "::>>rowapproval")
            return (
              <>
                {row?.approval == "pending" ?
                  <div className='actionbtndivmain'>
                    <button title='Approve' onClick={() => handleOpenConfirmationPopup(row?.dno_id, "approved")} class="acceptbtn">
                      <CheckIcon></CheckIcon>
                    </button>
                    <button title='Reject' onClick={() => handleOpenConfirmationPopup(row?.dno_id, "rejected")} class="rejectbtn">
                      <CloseIcon></CloseIcon>
                    </button>
                  </div> : <span className="statusLabel">{row?.approval}</span>}
              </>
            );
          };
        }
        if (key === "longitude") {
          column.hidden = true
        }
        if (key === 'suspend') {
          column.text = 'Suspend';
          column.formatter = (cell, row) => {
            return (
              <>
                <button title='Suspend' onClick={() => handleOpenConfirmationPopup(row?.dno_id, "suspended")} className='suspendBtn' disabled={row?.approval == "suspended" ? true : false}><DoNotTouchIcon></DoNotTouchIcon></button>
              </>
            );
          };
        }

        console.log(column, "::>>>>column");
        LabsColumns.push(column);
        LabAddedKeys.add(key);
      }
    });
  });

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredLabs =
    LabList &&
    LabList.filter((lab) =>
      Object.values(lab).some((val) =>
        String(val)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    );

  // const handleSyncData = () => {
  //   getAllData()
  // }

  const handleSyncData = () => {
    setDisabledSyncIcon(true);
    getAllData()

    setTimeout(() => {
      setDisabledSyncIcon(false);
    }, 60000);
  };

  const showSyncErrorToast = () => {
    showErrorToast("You have already synced data. Kindly wait for 1 minute.");
  }

  const handleScrollToTop = () => {
    const element = document.getElementById("mainTableId");
    if (element) {
      element.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  const handleScrollToBottom = () => {
    const element = document.getElementById("moveTotopBtn");
    if (element) {
      element.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <CssBaseline />
      <Container
        maxWidth="lg"
        className="p-0  fullheight createprofilepagemain labsdashboardpage"
      >
        <div className="createprofilepage">
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                className="appheadertitle"
                sx={{ flexGrow: 1 }}
              >
                Referral Facility
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className="signinpageupperpart">
            <Grid container spacing={2}>
              <Grid item xs={12} className="appnameright">
                <div className="apptitleholder w-100">
                  <div className="headerapplogo"></div>
                  <p className="apptitle px-20px">
                    Multi Disease Diagnostic Network Optimization{" "}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="" id="mainTableId">
            <Grid container spacing={2} className="formrow">
              <Grid item xs={12}>
                {/* <TableContainer component={Paper} className='usertable'>
                  {LabsColumns && LabsColumns.length > 0 &&
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      data={LabList && LabList}
                      columns={LabsColumns}
                      pagination={pagination}
                    />
                  }
                </TableContainer> */}
                <TableContainer component={Paper} className="usertable labsdashboardtable">
                  {/* <div className="refreshIcon">
                    <p onClick={() => handleSyncData()}>
                      <span>Sync</span>
                      <SyncTwoToneIcon />
                    </p>
                  </div> */}
                  {LabsColumns && LabsColumns.length > 0 && (
                    <div className="adminTable-dashboard">
                      <div className="searchbar-wrapper labs-search">
                        <p>Search: </p>
                        <TextField
                          placeholder="Search"
                          variant="outlined"
                          value={searchText}
                          onChange={handleSearch}
                          fullWidth
                          margin="normal"
                          className="mainSearchBar"
                        />
                        <div className="refreshIconWrapper">
                          {disabledSyncIcon ? <p onClick={() => showSyncErrorToast()}>Sync <SyncTwoToneIcon className="headerSyncBtn" /></p> :
                            <p onClick={() => handleSyncData()} >Sync <SyncTwoToneIcon className="headerSyncBtn" disabled={disabledSyncIcon} /></p>}
                        </div>
                        <div className="refreshIconWrapper">
                          <button className="multiUseGreenBtn" onClick={() => handleLoadMoreData()}>Load All</button>
                        </div>
                        <div className="refreshIconWrapper">
                          <button className="multiUseGreenBtn" onClick={() => handleScrollToBottom()}>Scroll To Bottom</button>
                        </div>
                      </div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={filteredLabs}
                        columns={LabsColumns}
                        pagination={pagination} //Custom pagination.
                      // pagination={paginationFactory()} //If this doesn't work try custom pagination written above.
                      />
                    </div>
                  )}
                  <ConfirmationPopup
                    handleOpenConfirmationPopup={handleOpenConfirmationPopup}
                    handleCloseConfirmationPopup={handleCloseConfirmationPopup}
                    openPopup={openPopup}
                    handleAction={handleAction}
                    selectedStatus={selectedStatus}
                    selectedPhoneNumber={selectedPhoneNumber}
                  />
                </TableContainer>
              </Grid>
            </Grid>
          </div>
          <button className="multiUseGreenBtn moveTotopBtn" onClick={handleScrollToTop} id="moveTotopBtn">Scroll To Top</button>
        </div>

        <Modal
          open={openMap}
          onClose={handleCloseMap}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="map-modal">
            <div
              className="closeBtnWrapper"
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <CloseOutlinedIcon
                className="mapSectCloseIcon"
                onClick={handleCloseMap}
              ></CloseOutlinedIcon>
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="mapWrapper">
                <MapContainer userLatLong={userLatLong} />
              </div>
            </Typography>
          </Box>
        </Modal>
      </Container>
    </React.Fragment>
  );
}

// =============================================OlD COde=================================

// import * as React from 'react';
// import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import CheckIcon from '@mui/icons-material/Check';
// import Autocomplete from '@mui/material/Autocomplete';
// import CloseIcon from '@mui/icons-material/Close';
// import * as URL from "../../services/url";
// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import { Circles } from 'react-loader-spinner';
// import Loader from '../../components/Loader/Loader';
// import Faciltyassign from './Faciltyassign';
// import { toast } from 'react-toastify';
// import Sidebar from '../../components/Sidebar/sidebar';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';

// export default function LabsDashboard() {
//   const [loading, setLoading] = useState(false)
//   const [LabList, setLabList] = useState([]);
//   const [sizePerPage, setSizePerPage] = useState(50)
//   const [pageSize, setPageSize] = useState(100)
//   const [pageNumber, setPageNumber] = useState(0)

//   let LabsColumns = [];
//   let LabAddedKeys = new Set();

//   useEffect(() => {
//     getAllData()
//   }, [pageSize, pageNumber])

//   const pagination = paginationFactory({
//     page: 1,
//     sizePerPage: sizePerPage,
//     lastPageText: ">>",
//     firstPageText: "<<",
//     nextPageText: ">",
//     prePageText: "<",
//     showTotal: true,
//     alwaysShowAllBtns: true,
//     onPageChange: function (page, sizePerPage) {
//       setPageNumber(page - 1)
//       setPageSize(pageSize)
//     },
//     onSizePerPageChange: function (page, sizePerPage) {
//       console.log(">>>page>>sizePerPage", page, sizePerPage);

//     }
//   });

//   const handleAction = async (labId, status) => {
//     setLoading(true)
//     const formData = new FormData();
//     formData.append("id", labId)
//     formData.append("status", status)
//     try {
//       const response = await axios.post(URL.labUpdateStatus, formData);
//       setLoading(false)
//       if (response.status == 200) {
//         console.log(response, "::>>response")
//         getAllData();
//       }
//     } catch (error) {
//       setLoading(false)
//       console.error('Error:', error);
//     }
//   }

//   const getAllData = async () => {
//     setLoading(true)
//     let reqBody = {
//       "pageNumber": pageNumber,
//       "pageSize": pageSize
//     }
//     try {
//       const response = await axios.post(URL.getAllLabs, reqBody);
//       setLoading(false)
//       if (response.status == 200) {
//         let responseData = response.data.Data;
//         const mergedArray = LabList.concat(responseData.filter(item2 =>
//           !LabList.some(item1 => item1.dno_id === item2.dno_id)
//         ));
//         setLabList(mergedArray)
//       }
//     } catch (error) {
//       setLoading(false)
//       console.error('Error:', error);
//     }
//   }

//   let transformedData = LabList.length > 0 && LabList.forEach(item => {
//     const entries = Object.entries(item);

//     entries.forEach(([key, value]) => {
//       if (!LabAddedKeys.has(key)) {
//         let column = { dataField: key, text: key };
//         if (key === 'facility_name') {
//           column.formatter = (cell, row) => {
//             return (
//               <p>{row?.facility_name ? row?.facility_name : "NA"}</p>
//             );
//           };
//         }
//         if (key === 'contact_number') {
//           column.formatter = (cell, row) => {
//             return (
//               <p>{row?.contact_number ? row?.contact_number : "NA"}</p>
//             );
//           };
//         }
//         if (key === 'email_address') {
//           column.formatter = (cell, row) => {
//             return (
//               <p>{row?.email_address ? row?.email_address : "NA"}</p>
//             );
//           };
//         }
//         if (key === 'approval') {
//           column.formatter = (cell, row) => {
//             console.log(row, "::>>rowapproval")
//             return (
//               <>
//                 {row?.approval == "pending" ?
//                   <div className='actionbtndivmain'>
//                     <button onClick={() => handleAction(row?.dno_id, "approved")} class="acceptbtn">
//                       <CheckIcon></CheckIcon>
//                     </button>
//                     <button onClick={() => handleAction(row?.dno_id, "rejected")} class="rejectbtn">
//                       <CloseIcon></CloseIcon>
//                     </button>
//                   </div> : row?.approval}
//               </>
//             );
//           };
//         }
//         LabsColumns.push(column);
//         LabAddedKeys.add(key);
//       }
//     });
//   });

//   return (
//     <React.Fragment>
//       {loading && <Loader />}
//       <CssBaseline />
//       <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
//         <div className='createprofilepage'>
//           <AppBar position="static">
//             <Toolbar>
//               <IconButton
//                 size="large"
//                 edge="start"
//                 color="inherit"
//                 aria-label="menu"
//                 sx={{ mr: 2 }}
//               >
//                 <Sidebar />
//               </IconButton>
//               <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
//                 Lab's List
//               </Typography>
//               {/* <Button color="inherit">Login</Button> */}
//             </Toolbar>
//           </AppBar>
//           <div className='signinpageupperpart'>
//             <Grid container spacing={2}>

//               <Grid item xs={12} className='appnameright'>
//                 <div className='apptitleholder w-100'>
//                   <div className='headerapplogo'>
//                   </div>
//                   <p className='apptitle px-20px'>

//                     Multi Disease Diagnostic Network Optimization </p>
//                 </div>
//               </Grid>
//             </Grid>
//           </div>

//           <div className=''>
//             <Grid container spacing={2} className='formrow'>
//               <Grid item xs={12}>
//                 <TableContainer component={Paper} className='usertable'>
//                   {LabsColumns && LabsColumns.length > 0 &&
//                     <BootstrapTable
//                       bootstrap4
//                       keyField="id"
//                       data={LabList && LabList}
//                       columns={LabsColumns}
//                       pagination={pagination}
//                     />
//                   }
//                 </TableContainer>
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </Container>
//     </React.Fragment>
//   );
// }
