import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

export default function Signup() {
  const navigate = useNavigate()
  const handleRouting = ()=>{
    navigate("/signin")
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0 bg-green fullheight'>
        <div className='splashscreenpage'>
          <div className='apptitleholder'>
            <div className='applogo'>
            </div>
            <p className='apptitle px-20px'>Multi Disease Diagnostic Network Optimization</p>
          </div>
          <Grid container spacing={2} className='signuppagemenuholder'>
            <Grid item xs={12} lg={6} md={6}>
              <div className='menuitemdiv'>
                <div className='mapmenudiv' onClick={handleRouting}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} className='mt-minus40px'>
                      <div className='mapimg'>
                      </div>
                    </Grid>
                    <Grid item xs={8} className='d-flex align-items-center'>
                      <p className='menuname'>STRiders</p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <div className='menuitemdiv '>
                <div className='mapmenudiv odd'>
                  <Grid container spacing={2} className='pr-20px'>
                    <Grid item xs={8} className='d-flex align-items-center'>
                      <p className='menuname pl-20px'>Laboratory</p>
                    </Grid>
                    <Grid item xs={4} className='mt-minus40px'>
                      <div className='courierimg'>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6} md={6}>
              <div className='menuitemdiv '>
                <div className='mapmenudiv'>
                  <Grid container spacing={2} className='pl-20px'>
                    <Grid item xs={4} className='mt-minus40px'>
                      <div className='facilityimg'>

                      </div>
                    </Grid>
                    <Grid item xs={8} className='d-flex align-items-center'>
                      <p className='menuname'>Facility</p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <div className='menuitemdiv '>
                <div className='mapmenudiv odd'>
                  <Grid container spacing={2} className='pr-20px'>
                    <Grid item xs={8} className='d-flex align-items-center'>
                      <p className='menuname pl-20px'>Courier</p>
                    </Grid>
                    <Grid item xs={4} className='mt-minus40px'>
                      <div className='guestimg'>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}