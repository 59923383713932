import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import * as URL from "../../services/url";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import Loader from '../../components/Loader/Loader';
import Faciltyassign from './Faciltyassign';
import { toast } from 'react-toastify';
import Sidebar from '../../components/Sidebar/sidebar';
import { ToastHandler, getErrorString } from '../../constants/utils';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';
import ConfirmationPopup from '../../components/Modals/ConfirmationPopup';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
// import SyncTwoToneIcon from "@mui/icons-material/SyncTwoTone";
import Slide from '@mui/material/Slide';
import { Dialog, Drawer } from "@material-ui/core";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApprovalDashboard() {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [facilities, setFacilities] = useState([])
  const [searchText, setSearchText] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const { showErrorToast, showSuccessToast } = ToastHandler();
  const [disabledSyncIcon, setDisabledSyncIcon] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const [selectePreviewDocument, setSelectePreviewDocument] = useState("");
  const [previewExtension, setPreviewExtension] = useState('');
  const [previewFileType, setPreviewFileType] = useState("");

  let StriderColumns = [];
  let StriderAddedKeys = new Set();


  const pagination = paginationFactory({
    page: 1,
    sizePerPage: sizePerPage,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    dropup: true,
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "400",
        value: 400,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "All",
        value: tableData.length,
      },
    ],
    onPageChange: function (page, sizePerPage) {
      setPageNumber(page - 1);
      setPageSize(pageSize);
    },
    onSizePerPageChange: function (page, sizePerPage) {

    },

  });

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    let reqBody = {
      "pageNumber": pageNumber,
      "pageSize": pageSize
    }
    setLoading(true)
    try {
      const response = await axios.post(URL.getusers, reqBody);
      setLoading(false)
      if (response.status == 200) {
        let responseData = response.data.data;
        if (tableData.length === 0 || pageNumber === 0) {
          setTableData(responseData);
        } else {
          const mergedArray = tableData.concat(
            responseData.filter(
              (item2) => !tableData.some((item1) => item1.id === item2.id)
            )
          );
          setTableData(mergedArray);
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleLoadMoreData = async () => {
    let reqBody = {
      "pageNumber": 0,
      "pageSize": 500
    }
    setLoading(true)
    try {
      const response = await axios.post(URL.getusers, reqBody);
      setLoading(false)
      if (response.status == 200) {
        let responseData = response.data.data;
        if (tableData.length === 0 || pageNumber === 0) {
          setTableData(responseData);
        } else {
          const mergedArray = tableData.concat(
            responseData.filter(
              (item2) => !tableData.some((item1) => item1.id === item2.id)
            )
          );
          setTableData(mergedArray);
        }
      }
    } catch (error) {
      setLoading(false)
    }

  }

  const handleAction = async (phoneNum, status) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("phonenumber", phoneNum)
    formData.append("status", status)
    try {
      const response = await axios.post(URL.updatestatus, formData);
      setLoading(false)
      if (response.status == 200) {
        showSuccessToast(response.data.message)
        getAllData()
        setOpenPopup(false)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      showErrorToast(getErrorString(error))
    }
  }

  const downloadDoc = async (id, type) => {
    let reqBody = {
      "id": id,
      "doctype": type
    }
    setLoading(true)
    try {
      const response = await axios.post(URL.downloaddoc, reqBody);
      setLoading(false)
      if (response.status == 200) {
        if (response.data.data.doc_url == null) {
          showErrorToast("File not found.");
          return;
        }
        else {
          toggleDrawer(!openPreviewImage, response.data.data.doc_url, type)
        }
        // var a = document.createElement("a"); //Create <a>
        // a.href = response.data.data.doc_url //Image Base64 Goes here
        // a.download = type //File name Here
        // a.click(); //Downloaded file
      }
    } catch (error) {
      showErrorToast(getErrorString(error))
    }

  }

  const downloadPreviewFile = async () => {
    if (!selectePreviewDocument) {
      setLoading(true);
      return;
    } else {
      setLoading(true);

      const a = document.createElement("a");
      a.href = selectePreviewDocument;
      a.download = previewFileType;
      a.click();

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };


  const searchFacility = async (e) => {
    if (e && e.target) {
      let reqBody = {
        "searchtxt": e.target.value,
      }
      try {
        const response = await axios.post(URL.getlabs, reqBody);
        setLoading(false)
        if (response && response.status == 200) {

          setFacilities(response.data.locations)
        }
      } catch (error) {
        showErrorToast(getErrorString(error))
      }
    }
  }

  const selectFacility = async (v, id) => {
    if (v) {
      let reqBody = {
        fid: v.dno_id,
        fname: v.facility_name,
        flat: v.latitude ? v.latitude : '',
        flong: v.longitude ? v.longitude : '',
        sid: id
      }
      try {
        const response = await axios.post(URL.assignfacility, reqBody);
        setLoading(false)
        if (response && response.status == 200) {
          getAllData()
        }
      } catch (error) {
        toast.error(error)
      }
    }
  }

  const clearSelect = async () => {
    setFacilities([])
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredTableData = tableData && tableData.filter(lab =>
    Object.values(lab).some(val =>
      String(val).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleOpenConfirmationPopup = (phone, selectedStatus) => {
    setOpenPopup(true)
    setSelectedPhoneNumber(phone);
    setSelectedStatus(selectedStatus);
  };

  const handleCloseConfirmationPopup = () => {
    setOpenPopup(false)
    setSelectedPhoneNumber("");
    setSelectedStatus("");
  };


  const handleSyncData = () => {
    setDisabledSyncIcon(true);
    getAllData()

    setTimeout(() => {
      setDisabledSyncIcon(false);
    }, 60000);
  };

  const showSyncErrorToast = () => {
    showErrorToast("You have already synced data. Kindly wait for 1 minute.");
  }

  let transformedData = tableData.length > 0 && tableData.forEach(item => {
    const entries = Object.entries(item);
    entries.forEach(([key, value]) => {
      if (!StriderAddedKeys.has(key)) {
        let column = { dataField: key, text: key, sort: true };

        if (key === 'id') {
          column.text = 'STRider Id';
        }
        if (key === 'fname') {
          column.text = 'First Name';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.fname ? row?.fname : "NA"}</p>
            );
          };
        }
        if (key === 'lname') {
          column.text = 'Last Name';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.lname ? row?.lname : "NA"}</p>
            );
          };
        }
        if (key === 'phonenumber') {
          column.text = 'Phone Number';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.phonenumber ? row?.phonenumber : "NA"}</p>
            );
          };
        }
        if (key === 'address') {
          column.text = 'Address';
          column.formatter = (cell, row) => {
            return (
              <p>{row?.address ? row?.address : "NA"}</p>
            );
          };
        }

        if (key === 'PersonalDocs') {
          column.text = 'Personal Documents';
          column.formatter = (cell, row) => {
            return (
              <>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "birthcert")} className='doclink' download="birthCertificate">Birth Certificate</a>
                </div>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "drivelic")} className='doclink' download="drivingLicense">Driving License</a>
                </div>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "passport")} className='doclink' download="passport">Passport</a>
                </div>
                {/* <div>
                  <a onClick={() => downloadDoc(row?.id, "birthcert")} className='doclink' download="birthCertificate">Birth Certificate</a>
                </div>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "drivelic")} className='doclink' download="drivingLicense">Driving License</a>
                </div>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "passport")} className='doclink' download="passport">Passport</a>
                </div> */}
              </>
            );
          };
        }

        if (key === 'VehicleDocs') {
          column.text = 'Vehicle Documents';
          column.formatter = (cell, row) => {
            return (
              <>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "regcert")} className='doclink' download="registrationCertificate">Registration Certificate</a>
                </div>
                <div>
                  <a onClick={() => downloadDoc(row?.id, "insurance")} className='doclink' download="insurancePolicy">Insurance Policy</a>
                </div>
              </>
            );
          };
        }
        if (key === "ReferralFacility") {
          column.text = "Referral Facility";
          column.formatter = (cell, row) => {
            return (
              <>
                <Faciltyassign id={row.id} />
              </>
            );
          };
        }
        if (key === 'approval') {
          column.text = 'Status';
          column.formatter = (cell, row) => {
            return (
              <>
                {row?.approval == "pending" ?
                  <div className='actionbtndivmain'>
                    <button title='Approve' onClick={() => handleOpenConfirmationPopup(row?.phonenumber, "approved")} class="acceptbtn">
                      <CheckIcon></CheckIcon>
                    </button>
                    <button title='Reject' onClick={() => handleOpenConfirmationPopup(row?.phonenumber, "rejected")} class="rejectbtn">
                      <CloseIcon></CloseIcon>
                    </button>
                  </div> : <span className="statusLabel">{row?.approval}</span>}
              </>
            );
          };
        }
        // if (key === "longitude") {
        //   column.hidden = true
        // }
        if (key === 'Suspend') {
          column.text = 'Suspend';
          column.formatter = (cell, row) => {
            return (
              <>
                <button title='Suspend' onClick={() => handleOpenConfirmationPopup(row?.phonenumber, "suspended")} className='suspendBtn' disabled={row?.approval == "suspended" ? true : false}><DoNotTouchIcon></DoNotTouchIcon></button>
              </>
            );
          };
        }
        StriderColumns.push(column);
        StriderAddedKeys.add(key);
      }
    })
  });

  const handleScrollToTop = () => {
    const element = document.getElementById("mainTableId");
    if (element) {
      element.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  const handleScrollToBottom = () => {
    const element = document.getElementById("moveTotopBtn");
    if (element) {
      element.scrollIntoView({
        behavior: 'auto'
      });
    }
  }


  const toggleDrawer = (open, selectedDoc, fileType) => {
    // const ext = getFileExtension(fileName);
    // setPreviewExtension(ext);
    setOpenPreviewImage(open);
    setSelectePreviewDocument(selectedDoc);
    setPreviewFileType(fileType)
    // downloadPreviewFile(fileType)
  };

  const getFileExtension = (selectedFileName) => {
    return selectedFileName && selectedFileName.slice((selectedFileName.lastIndexOf('.') - 1 >>> 0) + 2);
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar />
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                STRider List
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>

                    Multi Disease Diagnostic Network Optimization </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className='' id="mainTableId">
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <TableContainer component={Paper} className='usertable'>
                  <div className='striderDashboardTable'>
                    <div className='searchbar-wrapper'>
                      <p>Search:</p>
                      <TextField
                        placeholder="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearch}
                        fullWidth
                        margin="normal"
                        className='mainSearchBar'
                      />
                      <div className="refreshIconWrapper">
                        {disabledSyncIcon ? <p onClick={() => showSyncErrorToast()}>Sync <SyncTwoToneIcon className="headerSyncBtn" /></p> :
                          <p onClick={() => handleSyncData()} >Sync <SyncTwoToneIcon className="headerSyncBtn" disabled={disabledSyncIcon} /></p>}
                      </div>
                      <div className="refreshIconWrapper">
                        <button className="multiUseGreenBtn" onClick={() => handleLoadMoreData()}>Load All</button>
                      </div>
                      <div className="refreshIconWrapper">
                        <button className="multiUseGreenBtn" onClick={() => handleScrollToBottom()}>Scroll To Bottom</button>
                      </div>
                    </div>
                    {StriderColumns && StriderColumns.length > 0 && (
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={filteredTableData}
                        columns={StriderColumns}
                        pagination={pagination} //Custom pagination.
                      // pagination={paginationFactory()} //If this doesn't work try custom pagination written above.
                      />
                    )}
                  </div>
                  <button className="multiUseGreenBtn moveTotopBtn" onClick={handleScrollToTop} id="moveTotopBtn">Scroll To Top</button>

                  {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>STRider Id</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Phone Number</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="left">Uploaded Personal Documents</TableCell>
                        <TableCell align="left">Uploaded Vehicle Documents</TableCell>
                        <TableCell align='left'>Referral Facility</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Suspend</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTableData && filteredTableData.map((data, index) => {
                        return (
                          <TableRow>
                            <TableCell>{data?.id}</TableCell>
                            <TableCell align="left">{data?.fname}</TableCell>
                            <TableCell align="left">{data?.phonenumber}</TableCell>
                            <TableCell align="left">{data?.address}</TableCell>
                            <TableCell align="left">
                              <div>
                                <a onClick={() => downloadDoc(data?.id, "birthcert")} className='doclink' download="birthCertificate">Birth Certificate</a>
                              </div>
                              <div>
                                <a onClick={() => downloadDoc(data?.id, "drivelic")} className='doclink' download="drivingLicense">Driving License</a>
                              </div>
                              <div>
                                <a onClick={() => downloadDoc(data?.id, "passport")} className='doclink' download="passport">Passport</a>
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div>
                                <a onClick={() => downloadDoc(data?.id, "regcert")} className='doclink' download="registrationCertificate">Registration Certificate</a>
                              </div>
                              <div>
                                <a onClick={() => downloadDoc(data?.id, "insurance")} className='doclink' download="insurancePolicy">Insurance Policy</a>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Faciltyassign id={data.id} />
                            </TableCell>
                            <TableCell>
                              {data?.approval == "pending" ?
                                <div className='actionbtndivmain'>
                                  <button title='Approve' onClick={() => handleOpenConfirmationPopup(data?.phonenumber, "approved")} class="acceptbtn">
                                    <CheckIcon></CheckIcon>
                                  </button>
                                  <button title='Reject' onClick={() => handleOpenConfirmationPopup(data?.phonenumber, "rejected")} class="rejectbtn">
                                    <CloseIcon></CloseIcon>
                                  </button>
                                </div> : <span className="statusLabel">{data?.approval}</span>}
                            </TableCell>
                            <TableCell>
                              <button title='Suspend' onClick={() => handleOpenConfirmationPopup(data?.phonenumber, "suspended")} className='suspendBtn' disabled={data?.approval == "suspended" ? true : false}><DoNotTouchIcon></DoNotTouchIcon></button>
                            </TableCell>
                          </TableRow>
                        )
                      })}

                    </TableBody>
                  </Table> */}
                  <ConfirmationPopup
                    handleOpenConfirmationPopup={handleOpenConfirmationPopup}
                    handleCloseConfirmationPopup={handleCloseConfirmationPopup}
                    openPopup={openPopup}
                    handleAction={handleAction}
                    selectedStatus={selectedStatus}
                    selectedPhoneNumber={selectedPhoneNumber}
                  />
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </div>
        {selectePreviewDocument &&
          <div className="documentPreviewModal">
            <Dialog
              TransitionComponent={Transition}
              open={openPreviewImage && openPreviewImage}
              onClose={() => toggleDrawer(false, "", "")}
              className="documentPreviewModalInner"
            >
              <div className="d-flex justify-content-end align-items-center closeButton">
                <HighlightOffIcon onClick={() => toggleDrawer(false, "", "")}></HighlightOffIcon>
              </div>
              <h3><strong>Note: </strong>If preview is not visible, Kindly download the file.</h3>
              <div className="previewDocHolder">
                {/* <img className="selectePreviewDocument" src={selectePreviewDocument} /> */}
                <embed className="selectePreviewDocument" src={selectePreviewDocument} width="800" height="800" />
                {/* {previewExtension == "pdf" ? <embed className="pdfViewer"
                  src={selectePreviewDocument}
                  width="800" height="500" />
                  : <img className="selectePreviewDocument" src={selectePreviewDocument} />} */}
              </div>
              <div className='downloadPreviewImageWrapper'>
                <button className='assignbtn downloadPreviewImageBtn' onClick={() => downloadPreviewFile()}>Download</button>
              </div>
            </Dialog>
          </div>}
      </Container>
    </React.Fragment>
  );
}