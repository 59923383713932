import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


export default function Homepage() {
  const navigate = useNavigate()
  const handleClick = ()=>{
    navigate("/signup")
  }
  return (
    <div onClick={handleClick}>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" className='p-0 bg-green'>
        <Grid container spacing={2} className='px-20px'>
        <Grid item xs={6} className='homepageleftdiv'>
        <div className='homepage'>
        <div>
        <div className='homeapplogo'>
          </div>
        <p className='apptitle px-20px'>

  Multi Disease Diagnostic Network Optimization </p>
        </div>
        <div className='homepagediv'>
        
        </div>
        <div className='homepagelogoholderdiv'>
          {/* <div className='cdclogodiv'></div>
          <div className='whologodiv'></div>
          <div className='dohlogodiv'></div> */}
          <div className='homepagebtmlogorowdiv'></div>
        </div>
        
        </div>
        </Grid>
        <Grid item xs={6} className='homepageloginmaindiv'>
        <div className='homeapplogoholder'>
        <div className='homeapplogo'>
          </div>
        <p className='apptitle px-20px'>

  Multi Disease Diagnostic Network Optimization </p>
        </div>
        <div className='homepagelogin'>
          
           <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Username</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Password</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid>
          
           



      



           <p className='w-100'>
              <a href='#' className='greenbtn'>Login</a>
            </p> 
          </div>
          <div className='homepagebtmlogorowdiv'></div>
        </Grid>
        </Grid>
   
        </Container>
      </React.Fragment>
    </div>
  );
}