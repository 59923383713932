import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import * as URL from "../../services/url";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/sidebar';
import { CardContent } from '@mui/material';
import { Card, MenuItem, Select, FormControl, InputLabel, InputAdornment } from '@mui/material';

export default function DnoAdmin() {
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [region, setRegion] = useState('');
  const [accessType, setAccessType] = useState('');

  const handleAddUser = async () => {
    setLoading(true);
    const userData = {
      first_name: firstName,
      last_name: lastName,
      address: address,
      phonenumber: countryCode + phone,
      region: region,
      access: accessType
    };

    try {
      const response = await axios.post(URL.addAdmin, userData);
      setLoading(false);
      console.log(response.data);
      setFirstName('');
      setLastName('');
      setAddress('');
      setCountryCode('');
      setPhone('');
      setRegion('');
      setAccessType('');
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
        <div className='createprofilepage dno-adminpage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar />
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                DNO Admin
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>

                    Multi Disease Diagnostic Network Optimization </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div>
            <Card style={{ width: '38%', margin: 'auto', padding: 8 }} className='mt-10px mb-10px role-page'>
              <CardContent>
                <Grid container spacing={3} style={{ justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <p className='text-left'>First Name<span style={{ color: 'red' }}> *</span></p>
                    <TextField
                      label=""
                      fullWidth
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className='text-left'>Last Name<span style={{ color: 'red' }}> *</span></p>
                    <TextField
                      label=""
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className='text-left'>Address<span style={{ color: 'red' }}> *</span></p>
                    <TextField
                      label=""
                      fullWidth
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className='text-left'>Phone<span style={{ color: 'red' }}> *</span></p>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <Select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value="91">+91</MenuItem>
                            <MenuItem value="63">+63</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          label=""
                          fullWidth
                          value={phone}
                          type='number'
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <p className='text-left'>Region<span style={{ color: 'red' }}> *</span></p>
                    <FormControl fullWidth>

                      <Select
                        labelId="region-type-label"
                        id="region-type-select"
                        value={region}
                        label=""
                        onChange={(e) => setRegion(e.target.value)}
                        style={{ textAlign: 'left' }}
                      >
                        <MenuItem value="BARMM">BARMM</MenuItem>
                        <MenuItem value="CAR">CAR</MenuItem>
                        <MenuItem value="NCR">NCR</MenuItem>
                        <MenuItem value="Region I">Region I</MenuItem>
                        <MenuItem value="Region II">Region II</MenuItem>
                        <MenuItem value="Region III">Region III</MenuItem>
                        <MenuItem value="Region IV-A">Region IV-A</MenuItem>
                        <MenuItem value="Region IV-B">Region IV-B</MenuItem>
                        <MenuItem value="Region V">Region V</MenuItem>
                        <MenuItem value="Region VI">Region VI</MenuItem>
                        <MenuItem value="Region VII">Region VII</MenuItem>
                        <MenuItem value="Region VIII">Region VIII</MenuItem>
                        <MenuItem value="Region IX">Region IX</MenuItem>
                        <MenuItem value="Region X">Region X</MenuItem>
                        <MenuItem value="Region XI">Region XI</MenuItem>
                        <MenuItem value="Region XII">Region XII</MenuItem>
                        <MenuItem value="Region XIII">Region XIII</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <p className='text-left'>Access<span style={{ color: 'red' }}> *</span></p>
                    <FormControl fullWidth>

                      <Select
                        labelId="role-type-label"
                        id="role-type-select"
                        value={accessType}
                        label=""
                        onChange={(e) => setAccessType(e.target.value)}
                        style={{ textAlign: 'left' }}
                      >
                        <MenuItem value="Strider">Strider</MenuItem>
                        <MenuItem value="Labs">Labs</MenuItem>
                        <MenuItem value="Non-labs">Non-labs</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <p></p>
                    <Button variant="contained" color="primary" className='btn-add' onClick={handleAddUser}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}