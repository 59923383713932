import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import Loader from '../../components/Loader/Loader';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useEffect } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import * as URL from "../../services/url";
import Modal from 'react-bootstrap/Modal';

export default function UploadExcelModule() {
    const [loading, setLoading] = useState(false);
    const [getArray, setArray] = useState([]);
    const [age, setAge] = useState('');
    const [excelData, setExcelData] = useState("");
    const [excelFile, setExcelFile] = useState();
    const [fileInfo, setFileInfo] = useState("");
    const [finalTableData, setFinalTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0)
    const [pageSize, setPageSize] = useState(100)
    const [sizePerPage, setSizePerPage] = useState(50)

    let finalTableColums = [];
    let previewTableColumns = [];
    let addedKeys = new Set();
    let previewTableAddedKeys = new Set();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(fileInfo, "::>>fileInfo")

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        getStgLabsListData()
    }, [pageSize, pageNumber])


    const previewTablepagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        }
    });



    const pagination = paginationFactory({
        page: 1,
        sizePerPage: sizePerPage,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log(">>>page>>sizePerPage", page, sizePerPage);
            setPageNumber(page - 1)
            setPageSize(pageSize)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log(">>>page>>sizePerPage", page, sizePerPage);

        }
    });

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const hanldeChangeUploadFile = (e) => {
        let selectedFile = e.target.files[0];
        setFileInfo(selectedFile)
        if (selectedFile) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setExcelFile(e.target.result)
            }
        }

    }

    const handleFileSubmit = () => {
        setShow(true)
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: "buffer" })
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
            setExcelData(data)
            // setExcelData(data.slice(0, 10))

        }
    }

    let transformedData = finalTableData.length > 0 && finalTableData.forEach(item => {
        const entries = Object.entries(item);
        entries.forEach(([key, value]) => {
            if (!addedKeys.has(key)) {
                finalTableColums.push({ dataField: key, text: key });
                addedKeys.add(key);
            }
        });
    });

    let transformedexcelData = excelData && excelData.forEach(item => {        
        const entries = Object.entries(item);

        entries.forEach(([key, value]) => {
            if (!previewTableAddedKeys.has(key)) {
                previewTableColumns.push({ dataField: key, text: key });
                previewTableAddedKeys.add(key);
            }
        });
    });


    const getStgLabsListData = async () => {
        setLoading(true)
        let reqBody = {
            "pageNumber": pageNumber,
            "pageSize": pageSize
        }

        try {
            const response = await axios.post(URL.getStgLabs, reqBody);
            setLoading(false)
            if (response.status == 200) {
                let responseData = response.data.Data;                
                const mergedArray = finalTableData.concat(responseData.filter(item2 =>
                    !finalTableData.some(item1 => item1.dno_id === item2.dno_id)
                ));
                setFinalTableData(mergedArray);
            }
        } catch (error) {
            setLoading(false)
            console.error('Error>>>>:', error);
        }
    }

    const handleUploadExcel = async () => {
        const formData = new FormData();
        formData.append("execelfile", fileInfo)
        try {
            const response = await axios.post(URL.uploadExcel, formData);
            setLoading(false)
            if (response.status == 200) {
                console.log(response, "::>>response")
                // setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error('Error>>>>:', error);
        }
    }

    console.log(finalTableColums, "::>>finalTableColums");
    console.log(excelData, "::>excelData")
    console.log(excelFile, "::>excelFile")
    console.log(fileInfo, "::>fileInfo")
    // console.log(products, "::>products")
    console.log(finalTableData && finalTableData, "::>finalTableData")

    return (
        <React.Fragment>
            {loading && <Loader />}
            <CssBaseline />
            <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
                <div className='createprofilepage'>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                                Upload Excel
                            </Typography>
                            {/* <Button color="inherit">Login</Button> */}
                        </Toolbar>
                    </AppBar>
                    <div className='signinpageupperpart'>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className='appnameright'>
                                <div className='apptitleholder w-100'>
                                    <div className='headerapplogo'>
                                    </div>
                                    <p className='apptitle px-20px'>

                                        Multi Disease Diagnostic Network Optimization </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='uploadFileSection'>
                        <Grid container spacing={2} className='formrow'>
                            <Grid item xs={4} >
                                <Box sx={{ minWidth: 10 }} className="boxWrapper">
                                    <span className='inputLable'>Choose file type : </span><FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="File Type"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Test 1</MenuItem>
                                            <MenuItem value={20}>Test 2</MenuItem>
                                            <MenuItem value={30}>Test 3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ minWidth: 10 }} className="boxWrapper">
                                    <span className='inputLable'>Upload file : </span>
                                    <div className='uploadFileBtnWrapper'>
                                        <Button className='uploadFileBtn' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                            <span>Upload</span>
                                            <VisuallyHiddenInput onChange={(e) => hanldeChangeUploadFile(e)} type="file" />
                                        </Button>
                                        {fileInfo && <p className='uploadfilespan'>
                                            <span>File: </span>
                                            <span>{fileInfo?.name} </span>
                                        </p>}
                                    </div>
                                    {excelFile && <button className='previewBtn' onClick={handleFileSubmit}>Preview</button>}
                                    {excelFile && <button className='previewBtn' onClick={handleUploadExcel}>Upload Excel</button>}
                                </Box>
                            </Grid>
                            {/* <Grid item xs={8}>
                                <div className='main-tableheader'>
                                    <Typography>Excel Preview Table</Typography>
                                </div>
                                {!excelData && <h2 className='nopreviewheading'>No preview available</h2>}
                                {excelData && <TableContainer component={Paper} className='excelPreviewTable'>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={excelData}
                                        columns={finalTableColums}
                                        // defaultSorted={defaultSorted}
                                        pagination={previewTablepagination}
                                    />
                                </TableContainer>}
                            </Grid> */}
                        </Grid>
                    </div>

                    <div className=''>
                        <Grid container spacing={2} className='formrow excel-table final-excelTable'>
                            <Grid item xs={12}>
                                <div className='main-tableheader mainuploadtable'>
                                    <Typography>Excel Upload Table</Typography>
                                </div>
                                {finalTableData.length > 0 && <TableContainer component={Paper} className='usertable mt-3'>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={finalTableData.length > 0 && finalTableData}
                                        columns={finalTableColums}
                                        // defaultSorted={defaultSorted}
                                        pagination={pagination}
                                    />
                                </TableContainer>}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Modal dialogClassName="previewTableModal" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {excelData &&
                            <TableContainer component={Paper} className='excelPreviewTable'>
                                <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    data={excelData}
                                    columns={previewTableColumns}
                                    // defaultSorted={defaultSorted}
                                    pagination={previewTablepagination}
                                />
                            </TableContainer>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </React.Fragment>
    );
}