import { useRef } from "react";
import { toast } from "react-toastify";

export const getErrorString = (e, defaultErrorText = 'Something went wrong!') => {
    if(e && e.error && e.error.message && typeof e.error.message === 'string') {
      return e.error.message;
    };
  
    const errorString =
      e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string'
        ? e.response.data.message
        : e.message && typeof e.message === 'string'
          ? e.message
          : typeof e === 'string'
            ? e
            : defaultErrorText;
    return errorString;
  };


  export function ToastHandler() {
    const toastId = useRef(null);
  
    function showErrorToast(message) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(message);
      }
    }
  
    function showSuccessToast(message) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(message);
      }
    }
  
    return { showErrorToast, showSuccessToast };
  }