import { Card, CardContent, FormControl, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Loader from '../../components/Loader/Loader';
import * as URL from "../../services/url";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ToastHandler, getErrorString } from '../../constants/utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function DnoAdminModal(props) {
    const { openAdminModal, handleOpenAdminModal, handleCloseAdminModal, getAllData } = props;
    const { showErrorToast, showSuccessToast } = ToastHandler();
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [phone, setPhone] = useState('');
    const [region, setRegion] = useState('');
    const [accessType, setAccessType] = useState('');

    const userData = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        phonenumber: countryCode + phone,
        region: region,
        access: accessType
    };
    const handleAddUser = async () => {
        if (!firstName || !lastName || !address || !countryCode || !phone || !region || !accessType) {
            showErrorToast("Please enter all details");
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post(URL.addAdmin, userData);
            setLoading(false);
            if (response.status == 200) {
                showSuccessToast("User added successfully")
                setFirstName('');
                setLastName('');
                setAddress('');
                setCountryCode('');
                setPhone('');
                setRegion('');
                setAccessType('');
                getAllData();
                handleCloseAdminModal();
            }
        } catch (error) {
            setLoading(false);
            showErrorToast(getErrorString(error))
        }
    };

    const checkPhoneInput = (e) => {
        if (e.target.value.length <= 10) {
            setPhone(e.target.value);
        } else {
            showErrorToast("Please enter 10 digit mobile number.");
            return;
        }
    };

    return (
        <div>
            {loading && <Loader />}
            <Modal
                open={openAdminModal}
                onClose={handleCloseAdminModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='dnoAdminModal'
            >

                <Card style={{ width: '38%', margin: 'auto', padding: 8 }} className='mt-10px mb-10px role-page'>
                    <div className='textAlignEnd'>
                        <CloseOutlinedIcon onClick={() => handleCloseAdminModal()} className='mapSectCloseIcon'></CloseOutlinedIcon>
                    </div>
                    <CardContent>
                        <Grid container spacing={3} style={{ justifyContent: 'center' }}>
                            <Grid item xs={12}>
                                <p className='text-left'>First Name<span style={{ color: 'red' }}> *</span></p>
                                <TextField
                                    label=""
                                    fullWidth
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <p className='text-left'>Last Name<span style={{ color: 'red' }}> *</span></p>
                                <TextField
                                    label=""
                                    fullWidth
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <p className='text-left'>Address<span style={{ color: 'red' }}> *</span></p>
                                <TextField
                                    label=""
                                    fullWidth
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <p className='text-left'>Phone<span style={{ color: 'red' }}> *</span></p>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={countryCode}
                                                onChange={(e) => setCountryCode(e.target.value)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="91">+91</MenuItem>
                                                <MenuItem value="63">+63</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            label=""
                                            fullWidth
                                            value={phone}
                                            type='number'
                                            onChange={(e) => checkPhoneInput(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <p className='text-left'>Region<span style={{ color: 'red' }}> *</span></p>
                                <FormControl fullWidth>

                                    <Select
                                        labelId="region-type-label"
                                        id="region-type-select"
                                        value={region}
                                        label=""
                                        onChange={(e) => setRegion(e.target.value)}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <MenuItem value="BARMM">BARMM</MenuItem>
                                        <MenuItem value="CAR">CAR</MenuItem>
                                        <MenuItem value="NCR">NCR</MenuItem>
                                        <MenuItem value="Region I">Region I</MenuItem>
                                        <MenuItem value="Region II">Region II</MenuItem>
                                        <MenuItem value="Region III">Region III</MenuItem>
                                        <MenuItem value="Region IV-A">Region IV-A</MenuItem>
                                        <MenuItem value="Region IV-B">Region IV-B</MenuItem>
                                        <MenuItem value="Region V">Region V</MenuItem>
                                        <MenuItem value="Region VI">Region VI</MenuItem>
                                        <MenuItem value="Region VII">Region VII</MenuItem>
                                        <MenuItem value="Region VIII">Region VIII</MenuItem>
                                        <MenuItem value="Region IX">Region IX</MenuItem>
                                        <MenuItem value="Region X">Region X</MenuItem>
                                        <MenuItem value="Region XI">Region XI</MenuItem>
                                        <MenuItem value="Region XII">Region XII</MenuItem>
                                        <MenuItem value="Region XIII">Region XIII</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <p className='text-left'>Access<span style={{ color: 'red' }}> *</span></p>
                                <FormControl fullWidth>

                                    <Select
                                        labelId="role-type-label"
                                        id="role-type-select"
                                        value={accessType}
                                        label=""
                                        onChange={(e) => setAccessType(e.target.value)}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <MenuItem value="Strider">Strider</MenuItem>
                                        <MenuItem value="Labs">Labs</MenuItem>
                                        <MenuItem value="Non-labs">Non-labs</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className='addAdminBtnWrapper'>
                                <Button variant="contained" color="primary" className='btn-add addAdminBtn' onClick={handleAddUser}>
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    );
}