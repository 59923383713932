import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { Unstable_NumberInput as NumberInput } from '@mui/base';
import * as URL from "../../services/url";
import { toast } from 'react-toastify';
import { ToastHandler, getErrorString } from '../../constants/utils';
import Loader from '../../components/Loader/Loader';



export default function Signin() {
  const [countryCode, setCountryCode] = useState("+")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showSuccessToast } = ToastHandler();

  const navigate = useNavigate();
  const handleSubmitPhone = async () => {
    if (!phoneNumber) {
      showErrorToast("Please enter phone number")
      return;
    }
    if (countryCode.length == 1 || countryCode.length == 0) {
      showErrorToast("Please enter country code")
      return;
    }

    let reqPayload = {
      countrycode: `${countryCode}`,
      phonenumber: phoneNumber,
      type: "admin"
    }
    console.log(reqPayload, "::>> reqPayloady")
    setLoading(true)
    try {      
      const response = await axios.post(URL.getStriderOtp, reqPayload);
      setLoading(false)
      console.log('Response:', response.data);
      if (response.status == 200) {
        localStorage.setItem("countrycode", `${countryCode}`)
        localStorage.setItem("phoneNumber", phoneNumber)
        navigate("/otp");
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(getErrorString(error))
      console.log('Error sign in:', error);
      setLoading(false)
    }
  }

  const checkCountryCodeInput = (e) => {
    if (e.target.value.length <= 5) {
      setCountryCode(e.target.value)
    } else {
      showErrorToast("Country code cannot be greater than 5 digits.")
      return;
    }

  }

  const checkInput = (e) => {
    if (e.target.value.length <= 10) {
      setPhoneNumber(e.target.value)
    } else {
      showErrorToast("Please enter 10 digit mobile number.")
      return;
    }

  }

  console.log(countryCode, "::>>countryCode");
  console.log(phoneNumber, "::>>phoneNumber");
  return (
    <React.Fragment>
      {loading && <Loader />}

      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight signinpagemain'>
        <div className='signinpage'>
          <div className='signinpageupperpart'>
            <Grid container spacing={2} className='px-20px'>
              {/* <Grid item xs={8}>
        <div className='userpassimg'>
        </div>
       </Grid> */}
              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle'>Multi Disease Diagnostic Network Optimization</p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='labelname mb-0 text-center'>Access via mobile number</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={2}>
                {/* <TextField id="outlined-basic" label="" variant="outlined" placeholder='+65' /> */}
                <input
                  className='countryCodeInput'
                  onChange={(e) => checkCountryCodeInput(e)}
                  value={countryCode}
                  id="outlined-basic"
                  type='number'
                  placeholder="+"
                />
              </Grid>
              <Grid item xs={10}>
                <input
                  className='phoneNumberInput'
                  onChange={(e) => checkInput(e)}
                  value={phoneNumber}
                  id="outlined-basic"
                  type='number'
                  pattern="[0-9]*"
                />
                {/* <TextField id="outlined-basic" label="" variant="outlined" placeholder='Phone Number' /> */}
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
        <p className='labelname mb-0 text-center'>No account yet?</p>
        <p className='labelname mb-0 text-center mt-0 text-underline'>Register</p>
       </Grid> */}
          </div>

          <div className='signinpagelaterpart'>
            <p className='w-100'>
              <button onClick={handleSubmitPhone} className='greenbtn'>Send Access Code</button>
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}