import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import axios from 'axios';
import * as URL from "../../services/url";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { ToastHandler, getErrorString } from '../../constants/utils';

export default function Otppage() {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = ToastHandler();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  let finalOTP;
  const handlVerifyOtp = async () => {
    if (!finalOTP || finalOTP.toString().length <= 3) {
      showErrorToast("Please enter valid OTP");
      return;
    }
    let reqPayload = {
      countrycode: localStorage.getItem("countrycode"),
      phonenumber: localStorage.getItem("phoneNumber"),
      otp: finalOTP && finalOTP,
      type: "admin"
    }
    setLoading(true)
    try {
      const response = await axios.post(URL.verifyStriderOtp, reqPayload);
      setLoading(false)
      console.log('Response:', response.data);
      if (response.status == 200) {
        localStorage.setItem("userRole", response.data.userRole);
        localStorage.setItem("userAccess", response.data.access);
        localStorage.setItem("auth", JSON.stringify({ isAuth: true }));
        navigate("/strider-dashboard")
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error)
      console.error('Error:', error);
      setLoading(false)
    }
  }


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  finalOTP = otp.join('');
  console.log(finalOTP, ">>finalOTP")

  return (
    <React.Fragment>
      {loading && <Loader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight otppage'>
        <div className='signinpage'>
          <div className='signinpageupperpart'>
            <Grid container spacing={2} className='px-20px'>
              {/* <Grid item xs={8}>
        <div className='userpassimg'>

        </div>
       </Grid> */}
              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle'>Multi Disease Diagnostic Network Optimization</p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='labelname mb-0 text-center'>Enter Access Code</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={2}>

              </Grid>
              <div className="userInput">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-input"
                      type="text"
                      name="otp"
                      id="ist"
                      key={index}
                      value={data}
                      maxlength="1"
                      placeholder="-"
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              {/* <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits1(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits2(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits3(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits4(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid> */}
              <Grid item xs={2}>

              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
        <p className='labelname mb-0 text-center'>No account yet?</p>
        <p className='labelname mb-0 text-center mt-0 text-underline'>Register</p>
       </Grid> */}
          </div>

          <div className='signinpagelaterpart'>
            <p className='w-100'>
              <button onClick={handlVerifyOtp} className='greenbtn'>Submit</button>
            </p>
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}