import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import Sidebar from "../../components/Sidebar/sidebar";
import * as URL from "../../services/url";
import BootstrapTable from "react-bootstrap-table-next";
import DnoAdminModal from "./DnoAdminModal";
import { TextField } from "@mui/material";
import SyncTwoToneIcon from "@mui/icons-material/SyncTwoTone";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ToastHandler } from "../../constants/utils";

export default function DnoAdminDashboard() {
  const { showErrorToast, showSuccessToast } = ToastHandler();
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState("");
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [disabledSyncIcon, setDisabledSyncIcon] = useState(false);

  const handleOpenAdminModal = () => setOpenAdminModal(true);
  const handleCloseAdminModal = () => setOpenAdminModal(false);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(URL.getAllAdmins);
      setLoading(false);
      if (response.status == 200) {
        console.log(response, "::>>response>getAllAdmins");
        setAdminList(response.data.Data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  let adminListColumns = [];
  let AdminAddedKeys = new Set();

  let transformedData =
    adminList.length > 0 &&
    adminList.forEach((item) => {
      const entries = Object.entries(item);
      console.log(entries, "::>>entries");
      entries.forEach(([key, value]) => {
        if (!AdminAddedKeys.has(key)) {
          let column = { dataField: key, text: key, sort: true };
          if (key === "id") {
            column.text = "Admin Id";
          }
          if (key === "phonenumber") {
            column.text = "Contact Number";
          }
          if (key === "first_name") {
            column.text = "First Name";
          }
          if (key === "access_rights") {
            column.text = "Access Rights";
          }
          if (key === "role") {
            column.text = "Role";
          }
          if (key === "last_name") {
            column.text = "Last Name";
          }
          if (key === "address") {
            column.text = "Address";
          }
          if (key === "region") {
            column.text = "Region";
          }
          if (key === "program_role") {
            column.text = "Program Role";
          }
          if (key === "province_role") {
            column.text = "Province Role";
          }
          adminListColumns.push(column);
          AdminAddedKeys.add(key);
        }
      });
    });

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredAdminList =
    adminList &&
    adminList.filter((lab) =>
      Object.values(lab).some((val) =>
        String(val)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    );

  // const handleSyncData = () => {
  //   getAllData();
  // };

  const handleSyncData = () => {
    setDisabledSyncIcon(true);
    getAllData()

    setTimeout(() => {
      setDisabledSyncIcon(false);
    }, 60000);
  };

  const showSyncErrorToast = () => {
    showErrorToast("You have already synced data. Kindly wait for 1 minute.");
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <CssBaseline />
      <Container
        maxWidth="lg"
        className="p-0  fullheight createprofilepagemain nonlabsdashboardpage"
      >
        <div className="createprofilepage">
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                className="appheadertitle"
                sx={{ flexGrow: 1 }}
              >
                Admin List
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className="signinpageupperpart">
            <Grid container spacing={2}>
              <Grid item xs={12} className="appnameright">
                <div className="apptitleholder w-100">
                  <div className="headerapplogo"></div>
                  <p className="apptitle px-20px">
                    Multi Disease Diagnostic Network Optimization
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="">
            <Grid
              container
              spacing={2}
              className="formrow admindashboard adminTable-dashboard"
            >
              <Grid item xs={12}>
                <div className="modalButtonWrapper">
                  <div>
                    <button onClick={handleOpenAdminModal}>Create Admin</button>
                    <DnoAdminModal
                      getAllData={getAllData}
                      openAdminModal={openAdminModal}
                      handleOpenAdminModal={handleOpenAdminModal}
                      handleCloseAdminModal={handleCloseAdminModal}
                    />
                  </div>
                </div>
                <TableContainer component={Paper} className="usertable labsdashboardtable">
                  {/* <div className="refreshIcon">
                    <p onClick={() => handleSyncData()}>
                      <span>Sync</span>
                      <SyncTwoToneIcon />
                    </p>
                  </div> */}
                  <div className="searchbar-wrapper">
                    <p>Search: </p>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      fullWidth
                      margin="normal"
                      className="mainSearchBar"
                    />
                    <div className="refreshIconWrapper">
                      {/* <p onClick={() => handleSyncData()} >
                        <span>Sync</span>
                        <SyncTwoToneIcon />
                      </p> */}
                      {disabledSyncIcon ? <p onClick={() => showSyncErrorToast()}>Sync <SyncTwoToneIcon className="headerSyncBtn" /></p> :
                        <p onClick={() => handleSyncData()} >Sync <SyncTwoToneIcon className="headerSyncBtn" disabled={disabledSyncIcon} /></p>}
                    </div>
                  </div>
                  {adminListColumns && adminListColumns.length > 0 && (
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      data={filteredAdminList}
                      columns={adminListColumns}
                      pagination={paginationFactory()} //Custom pagination.
                    />
                  )}
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
