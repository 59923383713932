import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import ApprovalDashboard from './views/CreateProfile/approvalDashboard';
import Homepage from './views/Home/homepage';
import Otppage from './views/Otp/otppage';
import Signin from './views/Signin/signin';
import Signup from './views/Signup/signup';
import DnoAdmin from './views/CreateProfile/dnoAdmin'
import UploadExcelModule from './views/UploadModule/UploadExcelModule';
import LabsDashboard from "./views/CreateProfile/labsDashboard";
import NonLabsDashboard from "./views/CreateProfile/nonLabsDashboard";
import 'bootstrap/dist/css/bootstrap.min.css';
import DnoAdminDashboard from "./views/CreateProfile/DnoAdminDashboard";
import PostLoginRoutes from "./constants/PostLoginRoutes";
import PreLoginRoutes from "./constants/PreLoginRoutes";
import AdminAccessRoute from "./constants/AdminAccessRoute";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<PreLoginRoutes><Signin /></PreLoginRoutes>} />
        <Route path='/signup' element={<PreLoginRoutes><Signup /></PreLoginRoutes>} />
        <Route path='/signin' element={<PreLoginRoutes><Signin /></PreLoginRoutes>} />
        <Route path='/otp' element={<PreLoginRoutes><Otppage /></PreLoginRoutes>} />


        <Route path='/strider-dashboard' element={<PostLoginRoutes><ApprovalDashboard /></PostLoginRoutes>} />
        <Route path='/labs-dashboard' element={<PostLoginRoutes><LabsDashboard /></PostLoginRoutes>} />
        <Route path='/nonlabs-dashboard' element={<PostLoginRoutes><NonLabsDashboard /></PostLoginRoutes>} />
        <Route path='/dno-admin' element={<AdminAccessRoute><DnoAdmin /></AdminAccessRoute>} />
        <Route path='/admin-dashboard' element={<AdminAccessRoute><DnoAdminDashboard /></AdminAccessRoute>} />

        <Route path='/homepage' element={<Homepage />} />
        <Route path='/uploadexcel' element={<UploadExcelModule />} />

      </Routes>
    </div>
  );
}

export default App;
